import { Badge } from '@quintype/em/components/badge';
import { Button } from '@quintype/em/components/button';
import { Dialog } from '@quintype/em/components/dialog';
import get from 'lodash/get';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  updateConfigVersion,
  updateHaveUnsavedChanges,
  updateHistoryActionStatus,
  updatePBConfig
} from '../../actions/builder-action';
import updateRedirectUrl from '../../actions/redirect-action';
import { Close } from '../../atoms/close-svg';
import { SaveMessage } from '../../molecules/save-message';
import { IParams, IRedirectItem } from '../../utils/interfaces';
import { getSavedConfig, saveConfig } from '../../utils/utils';
import { EditRedirect } from './edit-redirect';
import styles from './index.module.css';
import { RedirectList } from './redirect-list';
import { SimultanoeusEditDialog } from './simulatneous-action-dialog';
import dialogStyles from './simulatneous-action-dialog.module.css';

export type EditType = 'add' | 'edit';

interface IErrorMessage {
  sourceUrl?: string[];
  destinationUrl?: string[];
  statusCode?: string[];
}

export interface IErrorMessageData {
  id: null | string;
  message: IErrorMessage;
}

const NEW_ITEM_ID = '__new';
const MAX_REDIRECTS = 50;

function removeId(items: IRedirectItem[]) {
  const newItems = items.map(item => ({
    sourceUrl: item.sourceUrl,
    destinationUrl: item.destinationUrl,
    statusCode: item.statusCode
  }));
  return newItems;
}

function addId(items: IRedirectItem[]) {
  const newItems = items.map(
    item =>
      ({
        sourceUrl: item.sourceUrl,
        destinationUrl: item.destinationUrl,
        statusCode: item.statusCode,
        id: item.sourceUrl
      } as IRedirectItem)
  );
  return newItems;
}

const newItemInitialState: IRedirectItem = {
  sourceUrl: '',
  destinationUrl: '',
  statusCode: '',
  id: NEW_ITEM_ID
};
export const Redirects = () => {
  const { config, editControl } = useSelector(state => get(state, ['builder']));
  const dispatch = useDispatch();
  const { publisherId = '', domain } = useParams<IParams>();
  const history = useHistory();

  const [isAddItemVisible, setAddItemVisibility] = useState(false);
  const [showSaveMsg, setShowSaveMsg] = useState(false);
  const deleteItemRef = useRef<null | string>(null);
  const itemRef = useRef<null | IRedirectItem[]>([]);
  const hasItemUpdated = useRef<boolean>(false);
  const [editItem, setEditItem] = useState<null | string>(null);
  const redirectUrls: IRedirectItem[] = get(config, ['redirectUrls'], []);
  const [error, setError] = useState<IErrorMessageData>({
    id: null,
    message: {}
  });
  const [newItem, setnewItemState] = useState<IRedirectItem>(newItemInitialState);
  const modifiedItems = useMemo(() => {
    return addId(redirectUrls);
  }, [redirectUrls]);
  const [items, setItems] = useState(modifiedItems || []);
  const [showSimultaneousDialog, setSimultanoeusDialogVisibility] = useState(false);
  const [dialogData, setDialogContext] = useState<{
    show: boolean;
    context: 'delete' | 'close' | '';
  }>({
    show: false,
    context: ''
  });
  const hasEditAccess = get(editControl, ['access'], false);

  useEffect(() => {
    // sync  store state to local state ( when store is updated , local state isn't updated and has old data)
    // also used  when initialising data from store
    setItems(modifiedItems);
  }, [modifiedItems]);

  // Q: get the  initial redirect url from api  and store it in itemRef
  // itemref is neeed so that when we click on close  and choose discard and save -> we can reset the store state  back to  previous saved value
  // issue - inital value from redux store  is [], the api response takes some time, how do we know the api response is [], when the initial value is []
  // this happens for first visit
  // A: make an additional api call to guarantee the redirect url is in infact empty

  useEffect(() => {
    async function getData() {
      const { config } = await getSavedConfig(publisherId, domain);
      itemRef.current = config.redirectUrls || [];
    }
    getData();
  }, [publisherId, domain]);

  const totalRedirections = redirectUrls.length;

  const navigateToHome = () => {
    history.push(`/accounts/${publisherId}/${domain}`);
  };

  function onDiscard() {
    const newConfig = {
      ...config,
      redirectUrls: itemRef.current
    };
    dispatch(updatePBConfig(newConfig));
    dispatch(updateHaveUnsavedChanges(false));
    navigateToHome();
  }

  function onItemEdit(data: IRedirectItem) {
    const newItems = items.map(item => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });

    setItems(newItems);
  }

  const onClose = () => {
    if (editItem || hasItemUpdated.current) {
      setDialogContext({
        context: 'close',
        show: true
      });
    } else {
      navigateToHome();
    }
  };

  function addNewUrl(data: IRedirectItem) {
    return [data, ...items];
  }

  function checkDuplicates(data: IRedirectItem) {
    const duplicatesExist = items.some(item => {
      return item.id !== data.id && item.sourceUrl.trim() === data.sourceUrl.trim();
    });

    if (duplicatesExist) return 'Already added redirection for the same source url';
    return '';
  }

  function validateData(data: IRedirectItem) {
    const error: {
      sourceUrl?: string[];
      destinationUrl?: string[];
      statusCode?: string[];
    } = {};

    const sourceUrl = data.sourceUrl;
    const destinationUrl = data.destinationUrl;
    const statusCode = data.statusCode;

    const invalidPathErrorMessage = 'Enter valid path';

    const duplicatesExist = checkDuplicates(data);
    if (duplicatesExist) error.sourceUrl = [duplicatesExist];
    if (!sourceUrl) {
      Array.isArray(error.sourceUrl)
        ? error.sourceUrl.push(invalidPathErrorMessage)
        : (error.sourceUrl = [invalidPathErrorMessage]);
    }

    if (!destinationUrl) error.destinationUrl = [invalidPathErrorMessage];
    if (sourceUrl.trim() && sourceUrl.trim().toLowerCase() === destinationUrl.trim().toLowerCase()) {
      const message = 'Source and destination path cannot be same';
      Array.isArray(error.destinationUrl) ? error.destinationUrl.push(message) : (error.destinationUrl = [message]);
    }
    if (sourceUrl && !sourceUrl.startsWith('/')) {
      const message = 'Source path should start with /';
      Array.isArray(error.sourceUrl) ? error.sourceUrl.push(message) : (error.sourceUrl = [message]);
    }

    if (destinationUrl && !destinationUrl.startsWith('/')) {
      const message = 'Destination path should start with /';
      Array.isArray(error.destinationUrl) ? error.destinationUrl.push(message) : (error.destinationUrl = [message]);
    }

    if (!statusCode) error.statusCode = ['Select redirection type'];
    return error;
  }

  function trimData({ data, id }: { data?: IRedirectItem; id?: string }) {
    if (data) {
      return {
        sourceUrl: data.sourceUrl.trim(),
        destinationUrl: data.destinationUrl.trim(),
        statusCode: data.statusCode
      };
    }
    return items.map(item => {
      if (item.id === id) {
        return {
          sourceUrl: item.sourceUrl.trim(),
          destinationUrl: item.destinationUrl.trim(),
          statusCode: item.statusCode
        };
      }

      return item;
    });
  }

  const addRedirectUrl = ({ data, type = 'edit', id }: { data?: IRedirectItem; type: EditType; id: string }) => {
    let redirectUrls: IRedirectItem[];
    if (type === 'add' && data) {
      const trimmedData = trimData({ data });
      redirectUrls = addNewUrl(trimmedData as IRedirectItem);
    } else {
      redirectUrls = trimData({ id }) as IRedirectItem[];
    }
    updateUrlInSTore(redirectUrls);
    hasItemUpdated.current = true;
  };

  function onItemAdd(type: EditType, id: string) {
    const item = id === NEW_ITEM_ID ? newItem : items.filter(item => item.id === id)[0];

    // returns falsy then valid
    // returns truthy invalid
    const validationMessage = validateData(item);
    if (Object.keys(validationMessage).length > 0) {
      setError({
        id,
        message: validationMessage
      });
      return false;
    }
    addRedirectUrl(type === 'add' ? { data: newItem, type, id } : { type, id });
    setEditItem(null);
    setError({
      id: null,
      message: {}
    });
    if (newItem.sourceUrl) {
      setnewItemState(newItemInitialState);
      setAddItemVisibility(false);
    }

    return true;
  }

  function onItemCancel() {
    setItems(modifiedItems);
    setEditItem(null);
    setError({
      id: null,
      message: {}
    });
  }

  function executeDelete() {
    const idToBeDeleted = deleteItemRef.current;
    const newItems = items.filter(item => item.id !== idToBeDeleted);
    updateUrlInSTore(newItems);
    hasItemUpdated.current = true;
    setDialogContext({
      show: false,
      context: ''
    });
  }

  function onItemDelete(id: string) {
    // storing item to be deleted in ref so that in can be referenced later
    deleteItemRef.current = id;
    setDialogContext({
      show: true,
      context: 'delete'
    });
  }

  function dialogTemplate() {
    return (
      <>
        <h3 className={dialogStyles.title}>
          {dialogData.context === 'delete' ? 'Delete Redirection?' : 'Unsaved changes found'}
        </h3>
        <p className={dialogStyles.content}>
          {dialogData.context === 'delete'
            ? 'This action cannot be undone. Are you sure you want to permanently delete this redirection?'
            : 'You have unsaved changes. Are you sure you want to exit without saving the changes you made?'}
        </p>
        <div className={dialogStyles['button_container']}>
          <Button onClick={dialogData.context === 'delete' ? executeDelete : onDiscard}>
            {dialogData.context === 'delete' ? 'Delete' : 'Yes, Exit'}
          </Button>
          <Button
            classname="redirect_dialog_cancel_button"
            type="primary"
            onClick={() => setDialogContext({ show: false, context: '' })}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }

  function updateUrlInSTore(urls: IRedirectItem[]) {
    const idRemovedUrls = removeId(urls);
    dispatch(updateRedirectUrl(idRemovedUrls));
  }

  const updateConfig = async (navigate: boolean = true) => {
    const nextVersion = config.version + 1;
    const updatedConfig = { ...config, version: nextVersion };
    const { activeVersion = 0, actionButtonStatus } = await saveConfig(publisherId, updatedConfig, domain);
    itemRef.current = updatedConfig.redirectUrls;
    batch(() => {
      dispatch(updateConfigVersion(activeVersion));
      dispatch(updateHistoryActionStatus(actionButtonStatus));
      dispatch(updateHaveUnsavedChanges(false));
    });
    if (navigate) {
      navigateToHome();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['inner_wrapper']}>
        <div className={styles['explanation_container']}>
          <h4 className={styles['explanation_title']}>How does it work?</h4>
          <p className={styles['explanation_content']}>
            You can redirect your existing pages to a new page by redirecting the links to different destination
          </p>
        </div>
        <div className={styles['main_wrapper']}>
          <header className={styles.header}>
            <h1 className={styles.heading}>Manage Redirections</h1>
            <div className={styles['redirection_details']}>
              <div className={styles['redirection_number']}>
                <span className={styles['redirection_number_title']}>Total Redirections</span>
                <Badge value={`${totalRedirections}/50`} isMuted={true} className="redirect__badge" />
              </div>
              <div className={styles['header_button_container']}>
                {hasEditAccess && (
                  <>
                    <Button
                      disabled={totalRedirections === MAX_REDIRECTS}
                      onClick={() => {
                        if (editItem) {
                          setSimultanoeusDialogVisibility(true);
                        } else {
                          setEditItem(NEW_ITEM_ID);
                          setAddItemVisibility(true);
                        }
                      }}
                    >
                      {' '}
                      Create New Redirection
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        if (editItem) {
                          setSimultanoeusDialogVisibility(true);
                        } else {
                          updateConfig(false);
                          hasItemUpdated.current = false;
                          setShowSaveMsg(true);
                          setTimeout(() => {
                            setShowSaveMsg(false);
                          }, 1500);
                        }
                      }}
                    >
                      {' '}
                      Save
                    </Button>
                  </>
                )}
                <SimultanoeusEditDialog
                  isOpen={showSimultaneousDialog}
                  onClose={() => setSimultanoeusDialogVisibility(false)}
                />
              </div>
            </div>
          </header>
          {isAddItemVisible ? (
            <div className="redirect__new-item">
              <EditRedirect
                title="Add Redirection"
                type="add"
                onAdd={onItemAdd}
                onCancel={() => {
                  setAddItemVisibility(false);
                  setEditItem(null);
                  setnewItemState(newItemInitialState);
                  setError({
                    id: null,
                    message: {}
                  });
                }}
                redirectData={newItem}
                onChange={data => setnewItemState(data)}
                error={error}
              />
            </div>
          ) : null}

          <RedirectList
            items={items}
            editItem={editItem}
            onItemChange={onItemEdit}
            onItemAdd={onItemAdd}
            onItemCancel={onItemCancel}
            onItemDelete={onItemDelete}
            onItemEditStart={(id: string) => setEditItem(id)}
            error={error}
            hasEditAccess={hasEditAccess}
          />
        </div>

        <Dialog isOpen={dialogData.show} onClose={() => setDialogContext({ show: false, context: '' })}>
          {dialogTemplate()}
        </Dialog>
      </div>
      {showSaveMsg && <SaveMessage />}
      <button className={styles['close_container']} onClick={onClose}>
        <Close />
      </button>
    </div>
  );
};
