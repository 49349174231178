import { Accordion, AccordionSection } from '@quintype/em/components/accordion';
import { Button } from '@quintype/em/components/button';
import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import OtherAdsScripts from '../../molecules/other-ads-scripts';
import styles from './secret-mode.module.css';
import { publishChanges, saveConfig } from '../../utils/utils';
import { IParams } from '../../utils/interfaces';

const SecretMode = () => {
  const config = useSelector(state => get(state, ['builder', 'config'], {}));
  const { publisherId = '', domain } = useParams<IParams>();

  const handlePublish = async () => {
    await saveConfig(publisherId, config, domain);
    await publishChanges(publisherId, config, domain);
    alert('Hurray! Your changes are published.');
  };

  return (
    <div className={styles.secretModeWrapper}>
      <div className={styles.accordionWrapper}>
        <Accordion hasSingleActivePanel onToggle={() => ({})}>
          <AccordionSection label="Other Ads scripts" isLabelUpperCase index={0}>
            <OtherAdsScripts />
          </AccordionSection>
        </Accordion>
      </div>
      <div className={styles.publish}>
        <Button type="primary" onClick={handlePublish}>
          Publish
        </Button>
      </div>
    </div>
  );
};

export default SecretMode;
