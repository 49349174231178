import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { TextArea } from '@quintype/em/components/text-area';
import { Radio, RadioOption } from '@quintype/em/components/radio-button';
import styles from './styles.module.css';
import {
  updateBackupConfig,
  updateConfigVersion,
  updateHaveUnsavedChanges,
  updateHistoryActionStatus,
  updatePBConfig,
  ampSetting
} from '../../actions/builder-action';
import { updateCustomCodeGeneralSettings } from '../../actions/custom-code-action';
import { checkValidEncodedCustomCode, encodeCustomCode, saveConfig } from '../../utils/utils';
import { Button } from '@quintype/em/components/button';
import { IParams } from '../../utils/interfaces';
import { Close } from '../../atoms/close-svg';
import { ConfirmationPopup } from '../confirmation-popup/index';
import { validateCode } from './amp-validator';
import { CustomCallout } from './custom-callout';
import { SaveMessage } from '../save-message';

const supportedPages = [{ pageName: 'ampGenericStoryPage', displayName: 'AMP Story Page' }];
const getPageDisplayName = (_pageName: string) => supportedPages.find(page => page.pageName === _pageName)?.displayName;

const AmpCustomCode = () => {
  const infiniteScrollExists = useSelector((state: any | {}) =>
    get(state, ['builder', 'config', 'general', 'amp', 'ampValidatorInfiniteScrollExists'], true)
  );
  const [currentTab, setCurrentTab] = useState('ampGenericStoryPage');
  const [showConfirmationPopup, toggleConfirmationPopup] = useState(false);
  const [validationPopup, setValidationPopup] = useState(false);
  const [validationStatus, setValidationStatus] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [testedHtmlStr, setTestedHtmlStr] = useState('');
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [showSaveMsg, setShowSaveMsg] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { publisherId = '', domain } = useParams<IParams>();
  const { insertToHead = '', insertToBody = '' } = useSelector((state: any) =>
    get(state, ['builder', 'config', 'general', 'tags', currentTab], {})
  );
  const { config, haveUnsavedChanges, backupConfig, editControl } = useSelector(state => get(state, ['builder']));
  const hasEditAccess = get(editControl, ['access'], false);

  const updateSettings = (key: string, value: string, currentValue: string) => {
    dispatch(
      updateCustomCodeGeneralSettings({
        [currentTab]: {
          ...{ insertToHead, insertToBody },
          ...{ [key]: encodeCustomCode(value) }
        }
      })
    );

    dispatch(updateHaveUnsavedChanges(true));
  };

  const infiniteScrollRadioBtnHandler = (e: any) => {
    const infScrExists = e.target.value === 'true';
    dispatch(ampSetting({ ampValidatorInfiniteScrollExists: infScrExists }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const navigateToHome = () => {
    history.push(`/accounts/${publisherId}/${domain}`);
  };

  const onActionButtonClick = async (navigate: boolean = true) => {
    const updatedConfig = { ...config, version: config.version + 1 };
    const { activeVersion = 0, actionButtonStatus } = await saveConfig(publisherId, updatedConfig, domain);
    batch(() => {
      dispatch(updateConfigVersion(activeVersion));
      dispatch(updateHistoryActionStatus(actionButtonStatus));
      dispatch(updateBackupConfig({ config }));
      dispatch(updateHaveUnsavedChanges(false));
    });
    if (navigate) {
      navigateToHome();
    }
  };

  const onCancel = () => toggleConfirmationPopup(false);

  const onDiscard = () => {
    dispatch(updatePBConfig(backupConfig));
    toggleConfirmationPopup(false);
    dispatch(updateHaveUnsavedChanges(false));
    navigateToHome();
  };

  const onClose = () => {
    if (haveUnsavedChanges) {
      toggleConfirmationPopup(true);
    } else {
      navigateToHome();
    }
  };

  const insertCode = (label: string, tag: string, value: string, key: string) => (
    <TextArea
      label={label}
      placeholder={`Paste any valid AMP HTML code here. The code will be inserted to the ${tag}`}
      value={checkValidEncodedCustomCode(value)}
      onChange={(data: string) => updateSettings(key, data, currentTab)}
      disabled={!hasEditAccess}
    />
  );

  const handleSaveMsg = () => {
    setShowSaveMsg(true);
    setTimeout(() => {
      setShowSaveMsg(false);
    }, 1500);
  };

  const confirmationPopupSaveHandler = () => {
    handleSaveMsg();
    onActionButtonClick();
  };

  const saveHandler = () => {
    handleSaveMsg();
    onActionButtonClick(false);
    setValidationPopup(false);
  };

  const validateHandler = async () => {
    try {
      setErrorMessage('');
      setTestedHtmlStr('');
      setWaitingForResponse(true);
      const { statusTxt, errorMsg = '', testedHtml = '' } = await validateCode({
        insertToHead,
        insertToBody,
        publisherId,
        infiniteScrollExists
      });
      setValidationStatus(statusTxt);
      setTestedHtmlStr(testedHtml);
      if (statusTxt === 'FAIL' || statusTxt === 'Fetch failed') setErrorMessage(errorMsg);
      setWaitingForResponse(false);
    } catch (err) {
      setWaitingForResponse(false);
      setErrorMessage('Something went wrong');
    }
  };

  const showValidationPopup = () => {
    setValidationPopup(true);
  };
  const hideValidationPopup = () => {
    setValidationPopup(false);
  };

  const Buttons = () => (
    <div>
      <span className={styles.validateWrapper}>
        <Button disabled={waitingForResponse} type="secondary" onClick={validateHandler}>
          {waitingForResponse ? 'Please Wait...' : 'Validate'}
        </Button>
      </span>
      <Button type="primary" onClick={showValidationPopup}>
        Save
      </Button>
    </div>
  );

  const InfiniteScrollRadioButton = () => (
    <div>
      <Radio
        name="infinite-scroll-exists"
        selected={infiniteScrollExists ? 'true' : 'false'}
        onChange={infiniteScrollRadioBtnHandler}
      >
        <RadioOption value="true">Website uses infinite scroll</RadioOption>
        <RadioOption value="false">Website does not use infinite scroll</RadioOption>
      </Radio>
    </div>
  );

  const ValidatorUi = () => (
    <div className={styles.standardizedFont}>
      {validationStatus && (
        <>
          <hr className={styles.codeSeparator} />
          <div className={styles.validationStatusWrapper}>
            Validation Status:
            <span
              className={styles.validationStatus}
              style={{ backgroundColor: validationStatus === 'PASS' ? '#2fd072' : '#ff214b' }}
            >
              {validationStatus}
            </span>
          </div>
        </>
      )}
      <div>
        {(validationStatus === 'FAIL' || validationStatus === 'Fetch failed') && (
          <div className={styles.validationErrorsWrapper}>
            <div className={styles.validationErrorsLabel}>Error:</div>
            <textarea
              className={styles.validationErrors}
              style={{ height: '100px' }}
              readOnly={true}
              value={errorMessage}
            ></textarea>
          </div>
        )}
        {testedHtmlStr && (
          <div>
            <div className={styles.testedCodeLabel}>Tested HTML code (provided for your reference only):</div>
            <textarea
              className={styles.testedCode}
              style={{ height: '250px' }}
              readOnly={true}
              value={testedHtmlStr}
            ></textarea>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.tagsGeneralSettingsWrapper}>
      <div>
        <h4>Insert code</h4>
        {supportedPages
          .map(page => page.displayName)
          .map((page, i) => (
            <div onClick={() => setCurrentTab(supportedPages[i].pageName)} key={page}>
              {page}
            </div>
          ))}
      </div>
      <div>
        <div className={styles.buttonsWrapper}>
          <h3>Insert code in {getPageDisplayName(currentTab)}</h3>
          {hasEditAccess && <Buttons />}
        </div>
        <div className={styles.calloutWrapper}>
          <CustomCallout />
        </div>
        {insertCode('Insert to head', '<head>', insertToHead, 'insertToHead')}
        {insertCode('Insert to body', '<body>', insertToBody, 'insertToBody')}
        <InfiniteScrollRadioButton />

        {!waitingForResponse && <ValidatorUi />}
      </div>
      <div className={styles.closeIcon} onClick={onClose}>
        <Close />
      </div>
      {showConfirmationPopup && (
        <ConfirmationPopup
          onSave={confirmationPopupSaveHandler}
          onCancel={onCancel}
          onDiscard={onDiscard}
          headingTxt="You have unsaved changes. Would you like to discard or save them?"
          subheadingTxt="Saving assumes your code is validated, and changes will apply after publishing."
        />
      )}
      {validationPopup && (
        <ConfirmationPopup
          onSave={saveHandler}
          onCancel={hideValidationPopup}
          onDiscard={hideValidationPopup}
          headingTxt="Warning: Confirm Code Validation"
          subheadingTxt="We assume your code has been validated. This action will save the code, and the changes will take effect upon publishing."
          discardBtnTxt="No, Go Back"
          saveBtnTxt="Yes, Code is Validated"
        />
      )}
      {showSaveMsg && <SaveMessage />}
    </div>
  );
};

export default AmpCustomCode;
