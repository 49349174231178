import React from 'react';
import styles from './save-message.module.css';

export function SaveMessage() {
  return (
    <div className={styles.saveMsgWrapper}>
      <p>Your changes are successfully saved.</p>
    </div>
  );
}
