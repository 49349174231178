import { TextArea } from '@quintype/em/components/text-area';
import camelCase from 'lodash/camelCase';
import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  updateBackupConfig,
  updateConfigVersion,
  updateHaveUnsavedChanges,
  updateHistoryActionStatus,
  updatePBConfig
} from '../../actions/builder-action';
import { updateCustomCodeGeneralSettings } from '../../actions/custom-code-action';
import { checkValidEncodedCustomCode, encodeCustomCode, saveConfig } from '../../utils/utils';
import { CopyIcon } from '../localization/copy';
import styles from './custom-code-general-settings.module.css';
import { get } from 'lodash';
import { Button } from '@quintype/em/components/button';
import { IParams } from '../../utils/interfaces';
import { useHistory, useParams } from 'react-router-dom';
import { Close } from '../../atoms/close-svg';
import { ConfirmationPopup } from '../confirmation-popup/index';
import { SaveMessage } from '../save-message';

interface IStore {
  builder: {
    config: {
      general: {
        tags?: {
          [key: string]: {
            insertToHead?: '';
            insertToBody?: '';
          };
        };
      };
    };
  };
}

const CustomCodeGeneralSettings = () => {
  const [currentTab, setCurrentTab] = useState('All Pages');
  const [showSaveMsg, setShowSaveMsg] = useState(false);
  const [showConfirmationPopup, toggleConfirmationPopup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { publisherId = '', domain } = useParams<IParams>();

  const tagConfig = useSelector((state: any) => get(state, ['builder', 'config', 'general', 'tags'], {}));

  const pageConvention = (tab: string) => camelCase(tab);

  const { insertToHead = '', insertToBody = '' } = get(tagConfig, [pageConvention(currentTab)], {});

  const { config, haveUnsavedChanges, backupConfig, editControl } = useSelector(state => get(state, ['builder']));
  const hasEditAccess = get(editControl, ['access'], false);
  const updateSettings = (key: string, value: string, currentValue: string) => {
    dispatch(
      updateCustomCodeGeneralSettings({
        [pageConvention(currentValue)]: {
          ...tagConfig[pageConvention(currentTab)],
          ...{ [key]: encodeCustomCode(value) }
        }
      })
    );
    dispatch(updateHaveUnsavedChanges(true));
  };

  const shortCode = [
    '[page-type]',
    '[story-slug]',
    '[story-id]',
    '[story-type]',
    '[primary-section]',
    '[sections]',
    '[sections-name]',
    '[sections-display-name]',
    '[authors-id]',
    '[authors]'
  ];

  const copyTextToClipboard = (text: string | null) => {
    const copyFrom = document.createElement('textarea');
    copyFrom.textContent = text;
    document.body.appendChild(copyFrom);
    copyFrom.select();
    document.execCommand('copy');
    copyFrom.blur();
    document.body.removeChild(copyFrom);
  };

  const supportedPages = [
    'All Pages',
    'Home Page',
    'Section Page',
    'Story Page',
    'Magazine Landing Page',
    'Issue Landing Page',
    'Magazine Archive Page',
    'Tag Page',
    'Search Page'
  ];

  const tabStyles = (page: string) => (currentTab === page ? styles.activeTab : styles.tabs);

  const navigateToHome = () => {
    history.push(`/accounts/${publisherId}/${domain}`);
  };

  const onActionButtonClick = async (navigate: boolean = true) => {
    const nextVersion = config.version + 1;
    const updatedConfig = { ...config, version: nextVersion };
    const { activeVersion = 0, actionButtonStatus } = await saveConfig(publisherId, updatedConfig, domain);
    batch(() => {
      dispatch(updateConfigVersion(activeVersion));
      dispatch(updateHistoryActionStatus(actionButtonStatus));
      dispatch(updateBackupConfig({ config }));
      dispatch(updateHaveUnsavedChanges(false));
    });
    if (navigate) {
      navigateToHome();
    }
  };

  const onCancel = () => toggleConfirmationPopup(false);

  const onDiscard = () => {
    dispatch(updatePBConfig(backupConfig));
    toggleConfirmationPopup(false);
    dispatch(updateHaveUnsavedChanges(false));
    navigateToHome();
  };

  const onClose = () => {
    if (haveUnsavedChanges) {
      toggleConfirmationPopup(true);
    } else {
      navigateToHome();
    }
  };

  const insertCode = (label: string, tag: string, value: string, key: string) => (
    <TextArea
      label={label}
      placeholder={`Paste any valid HTML code here. The code will be inserted to the end of ${tag} section, right before ${tag}`}
      value={checkValidEncodedCustomCode(value)}
      onChange={(data: string) => updateSettings(key, data, currentTab)}
      disabled={!hasEditAccess}
    />
  );

  const onSave = () => {
    onActionButtonClick(false);
    setShowSaveMsg(true);
    setTimeout(() => {
      setShowSaveMsg(false);
    }, 1500);
  };

  return (
    <div className={styles.tagsGeneralSettingsWrapper}>
      <div>
        <h4>Insert code</h4>
        {supportedPages.map(page => (
          <div onClick={() => setCurrentTab(page)} key={page} className={tabStyles(page)}>
            {page}
          </div>
        ))}
      </div>
      <div>
        <div className={styles.saveWrapper}>
          <h3>Insert Code in {currentTab}</h3>
          {hasEditAccess && (
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          )}
        </div>
        {insertCode('Insert to head', '</head>', insertToHead, 'insertToHead')}
        {insertCode('Insert to body', '</body>', insertToBody, 'insertToBody')}
        <div className={styles.shortCodeTitle}>Shortcodes</div>
        <div className={styles.shortCodeMsg}>
          Copy-paste the shortcodes below to integrate the variables in your code
        </div>
        <div className={styles.shortCodeTemplate}>
          {shortCode.map((shortCodeItem: string, shortCodeIndex: number) => {
            return (
              <div className={styles.copyWrapper} key={shortCodeIndex}>
                <span>{shortCodeItem}</span>
                <button className={styles.copyButton} onClick={() => copyTextToClipboard(shortCodeItem)}>
                  <CopyIcon />
                  <span>Copy Shortcode</span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.closeIcon} onClick={onClose}>
        <Close />
      </div>
      {showConfirmationPopup && (
        <ConfirmationPopup onCancel={onCancel} onSave={onActionButtonClick} onDiscard={onDiscard} />
      )}
      {showSaveMsg && <SaveMessage />}
    </div>
  );
};

export default CustomCodeGeneralSettings;
